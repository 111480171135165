import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { convertToCamelCaseArray } from '../../../utils/convertToCamelCase';

const {
    empty, loading, success, error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            subjectGroupsList: empty,
            subjectGroup: empty,
            createGroup: empty,
            updateGroup: empty,
            deleteGroup: empty,
        },
        subjectGroupsList: [],
        subjectGroup: {},
    },

    actions: {
        async getGroupsList({ commit }, query) {
            commit('setStatus', { type: 'subjectGroupsList', val: loading });
            try {
                const response = await http.get(query
                    ? `/directory/groups/?${query}` : '/directory/groups/', { authRequired: true });
                const { data } = response;
                commit('setGroupsList', data);
                commit('setStatus', { type: 'subjectGroupsList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'subjectGroupsList', val: error });
                return Promise.reject(err);
            }
        },

        async createGroup({ commit }, payload) {
            commit('setStatus', { type: 'createGroup', val: loading });
            try {
                const { data } = await http.post('/directory/groups/', payload, { authRequired: true });
                commit('setGroup', data);
                commit('setStatus', { type: 'createGroup', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'createGroup', val: error });
                return Promise.reject(err);
            }
        },

        async getGroupDetail({ commit }, id) {
            commit('setStatus', { type: 'subjectGroup', val: loading });
            try {
                const { data } = await http.get(`/directory/groups/${id}/`, { authRequired: true });
                commit('setGroup', data);
                commit('setStatus', { type: 'subjectGroup', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'subjectGroup', val: error });
                return Promise.reject(err);
            }
        },

        async updateGroup({ commit }, { id, payload }) {
            commit('setStatus', { type: 'updateGroup', val: loading });
            try {
                const { data } = await http.put(`/directory/groups/${id}/`, payload, { authRequired: true });
                commit('setGroup', data);
                commit('setStatus', { type: 'updateGroup', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'updateGroup', val: error });
                return Promise.reject(err);
            }
        },

        async deleteGroup({ commit }, id) {
            commit('setStatus', { type: 'deleteGroup', val: loading });
            try {
                const { status } = await http.delete(`/directory/groups/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteGroup', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteGroup', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setGroupsList(state, subjectGroupsList) {
            state.subjectGroupsList = convertToCamelCaseArray(subjectGroupsList);
        },

        setGroup(state, subjectGroup) {
            state.subjectGroup = subjectGroup;
        },
    },

    getters: {
        ...statusGetters,
    },
};
