import statuses from '../statuses';

const { loading, success, error } = statuses;

export default {
    getStatus: state => (type, status) => state.statuses[type] === status,
    isLoading: (state, getters) => type => getters.getStatus(type, loading),
    isSuccess: (state, getters) => type => getters.getStatus(type, success),
    isError: (state, getters) => type => getters.getStatus(type, error),
};
