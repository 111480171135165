import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { convertToCamelCaseArray, convertToCamelCaseObj } from '../../../utils/convertToCamelCase';
import { getStatusLabels } from '../../../utils/directory';

const {
    empty, loading, success, error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            subjectsList: empty,
            subject: empty,
            subjectsPagination: empty,
            createSubject: empty,
            updateSubject: empty,
            deleteSubject: empty,
        },
        subjectsList: [],
        subject: {},
        subjectsPagination: {},
        statusList: [
            { label: 'Черновик', value: 'draft' },
            { label: 'Опубликован', value: 'published' },
            { label: 'Не опубликован', value: 'not_published' },
        ],
    },

    actions: {
        async getSubjectsList({ commit }, query) {
            commit('setStatus', { type: 'subjectsList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/subjects/?${query}`
                    : '/directory/subjects/', { authRequired: true });
                commit('setSubjectsList', data);
                commit('setStatus', { type: 'subjectsList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'subjectsList', val: error });
                return Promise.reject(err);
            }
        },

        async paginationSubjectsList({ commit }, query = '') {
            commit('setStatus', { type: 'subjectsPagination', val: loading });
            try {
                const { data } = await http.options(`/directory/subjects/?${query}`, { authRequired: true });
                commit('setSubjectsPagination', data.pagination);
                commit('setStatus', { type: 'subjectsPagination', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'subjectsPagination', val: error });
                return Promise.reject(err);
            }
        },

        async createSubject({ commit }, payload) {
            commit('setStatus', { type: 'createSubject', val: loading });
            try {
                const { data } = await http.post('/directory/subjects/', payload, { authRequired: true });
                commit('setSubject', data);
                commit('setStatus', { type: 'createSubject', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'createSubject', val: error });
                return Promise.reject(err);
            }
        },

        async getSubjectDetail({ commit }, id) {
            commit('setStatus', { type: 'subject', val: loading });
            try {
                const { data } = await http.get(`/directory/subjects/${id}/`, { authRequired: true });
                commit('setSubject', data);
                commit('setStatus', { type: 'subject', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'subject', val: error });
                return Promise.reject(err);
            }
        },

        async updateSubject({ commit }, { id, payload }) {
            commit('setStatus', { type: 'updateSubject', val: loading });
            try {
                const { data } = await http.put(`/directory/subjects/${id}/`, payload, { authRequired: true });
                commit('setSubject', data);
                commit('setStatus', { type: 'updateSubject', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'updateSubject', val: error });
                return Promise.reject(err);
            }
        },

        async deleteSubject({ commit }, id) {
            commit('setStatus', { type: 'deleteSubject', val: loading });
            try {
                const { status } = await http.delete(`/directory/subjects/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteSubject', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteSubject', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setSubjectsList(state, subjectsList) {
            const directory = subjectsList.map(item => ({
                ...item.directory,
                id: item.id,
                statusText: getStatusLabels(item.directory.status),
            }));

            state.subjectsList = convertToCamelCaseArray(directory);
        },

        setSubjectsPagination(state, options) {
            state.subjectsPagination = convertToCamelCaseObj(options);
        },

        setSubject(state, subject) {
            state.subject = convertToCamelCaseObj(subject);
        },
    },

    getters: {
        ...statusGetters,
    },
};
