import { helpers } from '@vuelidate/validators';

export const normalizeDate = function (d) {
    // const D = new Date(date);
    // return `${D.getFullYear()}-${D.getMonth() + 1}-${D.getDate()}`;

    const date = new Date(d);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    return date.toLocaleString('ru-RU', options);
};

export const uuid4 = function () {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    const s13 = () => '4';
    const s17 = () => Math.floor((8 + Math.random()) * 0x10).toString(16).substring(0, 1);
    return `${s4() + s4()}-${s4()}-${s13()}${s4().substring(1)}-${s17()}${s4().substring(1)}-${s4() + s4() + s4()}`;
};

export const compareDates = ((date1, date2) => {
    const getDiferenceMillisecond = date2.getTime() - date1.getTime();

    const millisToMinutes = (millis) => {
        const minutes = Math.floor(millis / 60000);
        return minutes;
    };

    return Number(millisToMinutes(getDiferenceMillisecond));
});

export const formatDateToISO = function (d) {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const alphaLatin = helpers.regex(/^[^а-яА-Я]*$/);

export const truncateString = str => (str.length > 120 ? `${str.slice(0, 120)}...` : str);

export const camelToSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const findIndexOfRouter = (array, name) => array.findIndex(item => item.name === name);

export const findRouteByName = (routes, name) => routes.find(route => route.name === name) || {};

export const formatSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
};

export const searchObject = (array, id) => array.find(el => Number(el.id) === Number(id)) || {};
