import http from '../http';
import handleApiCall from '../apiCalls';
import statuses from '../statuses';

const {
    empty, loading,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            worktypes: empty,
            subjects: empty,
            subject: empty,
            subjectsPagination: empty,
            worktype: empty,
        },
        worktypes: [],
        subjects: [],
        subject: {},
        worktype: {},
        subjectsPagination: {},
    },

    actions: {
        async loadWorktypes({ commit }) {
            return handleApiCall(
                commit,
                'worktypes',
                () => http.get('/subjects/worktypes/', { authRequired: true }),
                data => commit('setWorktypes', data),
            );
        },

        async loadSubjects({ commit }, query) {
            return handleApiCall(
                commit,
                'subjects',
                () => http.get(`/subjects/subjects/?${query}`, { authRequired: true }),
                data => commit('setSubjects', data),
            );
        },

        async loadSubjectDetail({ commit }, id) {
            return handleApiCall(
                commit,
                'subject',
                () => http.get(`/subjects/subjects/${id}/`, { authRequired: true }),
                data => commit('setSubject', data),
            );
        },

        async paginationSubjects({ commit }, query) {
            return handleApiCall(
                commit,
                'subjectsPagination',
                () => {
                    const url = query ? `/subjects/subjects/?${query}/` : '/subjects/subjects/';
                    return http.options(url, { authRequired: true });
                },
                data => commit('updatePaginationSubject', data),
            );
        },

        async loadWorktypeDetail({ commit }, id) {
            return handleApiCall(
                commit,
                'worktype',
                () => http.get(`/subjects/worktypes/${id}/`, { authRequired: true }),
                data => commit('setWorktype', data),
            );
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setWorktypes(state, payload) {
            state.worktypes = payload;
        },

        setSubjects(state, payload) {
            state.subjects = payload;
        },

        setSubject(state, payload) {
            state.subject = payload;
        },

        setWorktype(state, payload) {
            state.worktype = payload;
        },

        updatePaginationSubject(state, payload) {
            state.subjectsPagination = payload;
        },
    },

    getters: {
        loadingWorktypes: state => state.statuses.worktypes === loading,
    },
};
