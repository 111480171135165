import directoryGroups from './directory/directoryGroups';
import directorySubjects from './directory/directorySubjects';
import directoryAuthorsProfile from './directory/directoryAuthorsProfile';
import directoryArticles from './directory/directoryArticles';
import directoryTerms from './directory/directoryTerms';
import directorySearch from './directory/directorySearch';

export default {
    directoryGroups,
    directorySubjects,
    directoryAuthorsProfile,
    directoryArticles,
    directoryTerms,
    directorySearch,
};
