import Vuex from 'vuex';
import loadProject from '../utils/projects';
import auth from './modules/auth';
import storage from './modules/storage';
import subjects from './modules/subjects';
import dialogue from './modules/dialogue';
import history from './modules/history';
import applicationStore from './modules/application';
import directoryModules from './modules/directory';

const modules = loadProject().routes;
const store = new Vuex.Store({
    actions: {
        storeInit({ commit }) {
            const savedTheme = localStorage.getItem('theme');

            if (!savedTheme) {
                const preferredTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
                commit('application/setColorTheme', preferredTheme);
                localStorage.setItem('theme', preferredTheme);
            } else {
                commit('application/setColorTheme', savedTheme);
            }

            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
                const newColorScheme = e.matches ? 'dark' : 'light';
                commit('application/setColorTheme', newColorScheme);
                localStorage.setItem('theme', newColorScheme);
            });
        },
    },

    modules: {
        auth,
        storage,
        subjects,
        dialogue,
        history,
        application: applicationStore,
        ...directoryModules,
    },
});

const importModule = async (moduleName) => {
    const module = await import(`./modules/${moduleName}`);
    store.registerModule(moduleName, module.default);
};

modules.forEach((moduleName) => {
    importModule(moduleName);
});

export default store;
