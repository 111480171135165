import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { convertToCamelCaseArray, convertToCamelCaseObj } from '../../../utils/convertToCamelCase';

const {
    empty, loading, success, error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            authorsList: empty,
            author: empty,
            authorsPagination: empty,
            createAuthor: empty,
            updateAuthor: empty,
            deleteAuthor: empty,
        },
        authorsList: [],
        author: {},
        authorsPagination: {},
    },

    actions: {
        async getAuthorsList({ commit }, query) {
            commit('setStatus', { type: 'authorsList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/authors/?${query}`
                    : '/directory/authors/', { authRequired: true });
                commit('setAuthorsList', data);
                commit('setStatus', { type: 'authorsList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'authorsList', val: error });
                return Promise.reject(err);
            }
        },

        async paginationAuthorsList({ commit }, query = '') {
            commit('setStatus', { type: 'authorsPagination', val: loading });
            try {
                const { data } = await http.options(`/directory/authors/?${query}`, { authRequired: true });
                commit('setAuthorsPagination', data.pagination);
                commit('setStatus', { type: 'authorsPagination', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'authorsPagination', val: error });
                return Promise.reject(err);
            }
        },

        async createAuthor({ commit }, payload) {
            commit('setStatus', { type: 'createAuthor', val: loading });
            try {
                const { data } = await http.post('/directory/authors/', payload, { authRequired: true });
                commit('setAuthor', data);
                commit('setStatus', { type: 'createAuthor', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'createAuthor', val: error });
                return Promise.reject(err);
            }
        },

        async getAuthorDetail({ commit }, id) {
            commit('setStatus', { type: 'author', val: loading });
            try {
                const { data } = await http.get(`/directory/authors/${id}/`, { authRequired: true });
                commit('setAuthor', data);
                commit('setStatus', { type: 'author', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'author', val: error });
                return Promise.reject(err);
            }
        },

        async updateAuthor({ commit }, { id, payload }) {
            commit('setStatus', { type: 'updateAuthor', val: loading });
            try {
                const { data } = await http.put(`/directory/authors/${id}/`, payload, { authRequired: true });
                commit('setAuthor', data);
                commit('setStatus', { type: 'updateAuthor', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'updateAuthor', val: error });
                return Promise.reject(err);
            }
        },

        async deleteAuthor({ commit }, id) {
            commit('setStatus', { type: 'deleteAuthor', val: loading });
            try {
                const { status } = await http.delete(`/directory/authors/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteAuthor', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteAuthor', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setAuthorsList(state, authorsList) {
            const authors = authorsList.map(item => ({
                ...item,
                fullName: `${item.first_name} ${item.last_name}`,
            }));

            state.authorsList = convertToCamelCaseArray(authors);
        },

        setAuthorsPagination(state, options) {
            state.authorsPagination = convertToCamelCaseObj(options);
        },

        setAuthor(state, author) {
            state.author = convertToCamelCaseObj(author);
        },
    },

    getters: {
        ...statusGetters,
    },
};
