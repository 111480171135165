import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { getStatusLabels } from '../../../utils/directory';
import { convertToCamelCaseArray, convertToCamelCaseObj } from '../../../utils/convertToCamelCase';

const {
    empty, loading, success, error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            articlesList: empty,
            article: empty,
            articlesPagination: empty,
            createArticle: empty,
            updateArticle: empty,
            deleteArticle: empty,
        },
        articlesList: [],
        article: {},
        articlesPagination: {},
    },

    actions: {
        async getArticlesList({ commit }, query) {
            commit('setStatus', { type: 'articlesList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/articles/?${query}`
                    : '/directory/articles/', { authRequired: true });
                commit('setArticlesList', data);
                commit('setStatus', { type: 'articlesList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'articlesList', val: error });
                return Promise.reject(err);
            }
        },

        async paginationArticlesList({ commit }, query = '') {
            commit('setStatus', { type: 'articlesPagination', val: loading });
            try {
                const { data } = await http.options(`/directory/articles/?${query}`, { authRequired: true });
                commit('setArticlesPagination', data.pagination);
                commit('setStatus', { type: 'articlesPagination', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'articlesPagination', val: error });
                return Promise.reject(err);
            }
        },

        async createArticle({ commit }, payload) {
            commit('setStatus', { type: 'createArticle', val: loading });
            try {
                const { data } = await http.post('/directory/articles/', payload, { authRequired: true });
                commit('setArticle', data);
                commit('setStatus', { type: 'createArticle', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'createArticle', val: error });
                return Promise.reject(err);
            }
        },

        async getArticleDetail({ commit }, id) {
            commit('setStatus', { type: 'article', val: loading });
            try {
                const { data } = await http.get(`/directory/articles/${id}/`, { authRequired: true });
                commit('setArticle', data);
                commit('setStatus', { type: 'article', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'article', val: error });
                return Promise.reject(err);
            }
        },

        async updateArticle({ commit }, { id, payload }) {
            commit('setStatus', { type: 'updateArticle', val: loading });
            try {
                const { data } = await http.put(`/directory/articles/${id}/`, payload, { authRequired: true });
                commit('setArticle', data);
                commit('setStatus', { type: 'updateArticle', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'updateArticle', val: error });
                return Promise.reject(err);
            }
        },

        async deleteArticle({ commit }, id) {
            commit('setStatus', { type: 'deleteArticle', val: loading });
            try {
                const { status } = await http.delete(`/directory/articles/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteArticle', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteArticle', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setArticlesList(state, articlesList) {
            const articles = articlesList.map(item => ({
                ...item,
                title: item.directory.short_name,
                slug: item.directory.slug,
                updated: item.directory.updated,
                status: item.directory.status,
                statusText: getStatusLabels(item.directory.status),
            }));
            state.articlesList = convertToCamelCaseArray(articles);
        },

        setArticlesPagination(state, options) {
            state.articlesPagination = convertToCamelCaseObj(options);
        },

        setArticle(state, article) {
            state.article = convertToCamelCaseObj(article);
        },
    },

    getters: {
        ...statusGetters,
    },
};
