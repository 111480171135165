import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { convertToCamelCaseArray, convertToCamelCaseObj } from '../../../utils/convertToCamelCase';

const {
    empty, loading, success, error,
} = statuses;

export default {
    namespaced: true,
    state: {
        statuses: {
            termsList: empty,
            termDetails: empty,
            termsPagination: empty,
            createTerm: empty,
            updateTerm: empty,
            deleteTerm: empty,
            relatedTerms: empty,
            relatedTermsList: empty,
            termsRelatedPagination: empty,
        },
        termsList: [],
        termDetails: {},
        termsPagination: {},
    },

    actions: {
        async getTermsList({ commit }, query = '') {
            commit('setStatus', { type: 'termsList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/terms/?${query}`
                    : '/directory/terms/', { authRequired: true });
                commit('setTermsList', data);
                commit('setStatus', { type: 'termsList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'termsList', val: error });
                return Promise.reject(err);
            }
        },

        async paginationTermsList({ commit }, query = '') {
            commit('setStatus', { type: 'termsPagination', val: loading });
            try {
                const { data } = await http.options(`/directory/terms/?${query}`, { authRequired: true });
                commit('setTermsPagination', data.pagination);
                commit('setStatus', { type: 'termsPagination', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'termsPagination', val: error });
                return Promise.reject(err);
            }
        },

        async createTerm({ commit }, payload) {
            commit('setStatus', { type: 'createTerm', val: loading });
            try {
                const { data } = await http.post('/directory/terms/', payload, { authRequired: true });
                commit('setTermDetails', data);
                commit('setStatus', { type: 'createTerm', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'createTerm', val: error });
                return Promise.reject(err);
            }
        },

        async getTermDetail({ commit }, id) {
            commit('setStatus', { type: 'termDetails', val: loading });
            try {
                const { data } = await http.get(`/directory/terms/${id}/`, { authRequired: true });
                commit('setTermDetails', data);
                commit('setStatus', { type: 'termDetails', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'termDetails', val: error });
                return Promise.reject(err);
            }
        },

        async getRelatedTerms({ commit }, termIds) {
            commit('setStatus', { type: 'relatedTerms', val: loading });
            try {
                const fetchedTerms = await Promise.all(
                    termIds.map(async (id) => {
                        const { data } = await http.get(`/directory/terms/${id}/`, { authRequired: true });
                        return data;
                    }),
                );
                commit('setStatus', { type: 'relatedTerms', val: success });
                return Promise.resolve(fetchedTerms);
            } catch (err) {
                commit('setStatus', { type: 'relatedTerms', val: error });
                return Promise.reject(err);
            }
        },

        async getRelatedTermsList({ commit }, query = '') {
            commit('setStatus', { type: 'relatedTermsList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/terms/?${query}`
                    : '/directory/terms/', { authRequired: true });
                commit('setStatus', { type: 'relatedTermsList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'relatedTermsList', val: error });
                return Promise.reject(err);
            }
        },

        async paginationRelatedTermsList({ commit }, query = '') {
            commit('setStatus', { type: 'termsRelatedPagination', val: loading });
            try {
                const { data } = await http.options(`/directory/terms/?${query}`, { authRequired: true });
                commit('setStatus', { type: 'termsRelatedPagination', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'termsRelatedPagination', val: error });
                return Promise.reject(err);
            }
        },

        async updateTerm({ commit }, { id, payload }) {
            commit('setStatus', { type: 'updateTerm', val: loading });
            try {
                const { data } = await http.put(`/directory/terms/${id}/`, payload, { authRequired: true });
                commit('setTermDetails', data);
                commit('setStatus', { type: 'updateTerm', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'updateTerm', val: error });
                return Promise.reject(err);
            }
        },

        async deleteTerm({ commit }, id) {
            commit('setStatus', { type: 'deleteTerm', val: loading });
            try {
                const { status } = await http.delete(`/directory/terms/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteTerm', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteTerm', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setTermsList(state, termsList) {
            state.termsList = convertToCamelCaseArray(termsList);
        },

        setTermsPagination(state, paginationData) {
            state.termsPagination = convertToCamelCaseObj(paginationData);
        },

        setTermDetails(state, termDetails) {
            state.termDetails = convertToCamelCaseObj(termDetails);
        },
    },

    getters: {
        ...statusGetters,
    },
};
