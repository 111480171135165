import http from '../../http';
import statuses from '../../statuses';
import statusGetters from '../../getters/statusGetters';
import { convertToCamelCaseArray, convertToCamelCaseObj } from '../../../utils/convertToCamelCase';

const {
    empty, loading, success, error,
} = statuses;

// const mockSearchList = [
//     {
//         id: '507f1f77bcf86cd799439011',
//         query: 'Запрос 1',
//         source: 'articles',
//         created: '2024-09-12T10:06:04.031Z',
//         user_id: 'user123',
//         results_found: 15,
//     },
//     {
//         id: '507f1f77bcf86cd799439012',
//         query: 'Запрос 2',
//         source: 'terms',
//         created: '2024-09-11T09:12:32.002Z',
//         user_id: 'user456',
//         results_found: 8,
//     },
//     {
//         id: '507f1f77bcf86cd799439013',
//         query: 'Запрос 3',
//         source: 'articles',
//         created: '2024-09-10T14:23:11.100Z',
//         user_id: 'user789',
//         results_found: 12,
//     },
//     {
//         id: '507f1f77bcf86cd799439014',
//         query: 'Запрос 4',
//         source: 'terms',
//         created: '2024-09-09T11:45:44.321Z',
//         user_id: 'user101',
//         results_found: 20,
//     },
//     {
//         id: '507f1f77bcf86cd799439015',
//         query: 'Запрос 5',
//         source: 'articles',
//         created: '2024-09-08T08:30:56.789Z',
//         user_id: 'user102',
//         results_found: 5,
//     },
// ];

// const mockSearchDetail = {
//     id: '507f1f77bcf86cd799439011',
//     query: 'Запрос 1',
//     source: 'articles',
//     created: '2024-09-12T10:06:04.031Z',
//     user_id: 'user123',
//     results_found: 15,
// };

export default {
    namespaced: true,
    state: {
        statuses: {
            searchList: empty,
            searchDetails: empty,
            deleteSearch: empty,
        },
        searchList: [],
        searchDetails: {},
    },

    actions: {
        async getSearchList({ commit }, query = '') {
            commit('setStatus', { type: 'searchList', val: loading });
            try {
                const { data } = await http.get(query ? `/directory/search/?${query}`
                    : '/directory/search/', { authRequired: true });
                commit('setSearchList', data);
                // commit('setSearchList', mockSearchList);
                commit('setStatus', { type: 'searchList', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'searchList', val: error });
                return Promise.reject(err);
            }
        },

        async getSearchDetail({ commit }, id) {
            commit('setStatus', { type: 'searchDetails', val: loading });
            try {
                const { data } = await http.get(`/directory/search/${id}/`, { authRequired: true });
                commit('setSearchDetails', data);
                // commit('setSearchDetails', mockSearchDetail);
                commit('setStatus', { type: 'searchDetails', val: success });
                return Promise.resolve(data);
            } catch (err) {
                commit('setStatus', { type: 'searchDetails', val: error });
                return Promise.reject(err);
            }
        },

        async deleteSearch({ commit }, id) {
            commit('setStatus', { type: 'deleteSearch', val: loading });
            try {
                const { status } = await http.delete(`/directory/search/${id}/`, { authRequired: true });
                if (status === 204) {
                    commit('setStatus', { type: 'deleteSearch', val: success });
                }
                return Promise.resolve(status);
            } catch (err) {
                commit('setStatus', { type: 'deleteSearch', val: error });
                return Promise.reject(err);
            }
        },
    },

    mutations: {
        setStatus(state, { type, val }) {
            state.statuses[type] = val;
        },

        setSearchList(state, searchList) {
            state.searchList = convertToCamelCaseArray(searchList);
        },

        setSearchDetails(state, searchDetails) {
            state.searchDetails = convertToCamelCaseObj(searchDetails);
        },
    },

    getters: {
        ...statusGetters,
    },
};
