export const convertToCamelCaseObj = (obj, iconKeys = []) => {
    if (!obj) return obj;
    const newObj = {};

    Object.keys(obj).forEach((key) => {
        let newKey = key.replace(/_(.)/g, (match, group1) => group1.toUpperCase());
        newKey = newKey[0].toLowerCase() + newKey.slice(1);

        if (iconKeys.includes(key)) {
            const iconName = `${newKey}Icon`;
            if (obj[key] === true) {
                newObj[iconName] = 'circle-check';
            } else if (obj[key] === false) {
                newObj[iconName] = 'circle-xmark';
            } else {
                newObj[iconName] = 'question';
            }
        }

        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            newObj[newKey] = convertToCamelCaseObj(obj[key]);
        } else {
            newObj[newKey] = obj[key];
        }
    });

    return newObj;
};

export const convertToCamelCaseArray = (el, iconKey) => el.map((i => convertToCamelCaseObj(i, iconKey)));
